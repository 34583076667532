import React, { useCallback, useEffect } from 'react'
import { ApolloClient, useLazyQuery } from '@apollo/client'
import LazyHydrate from 'react-lazy-hydration'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { FloatingCallToActionImage } from '@/molecules/FloatingCallToAction/FloatingCallToActionImage'
import { GET_FLOATING_CALL_TO_ACTION_QUERY } from '@/services/CmsService'
import { GuildPermissions } from '@/services/GuildUserService'
import { GetFloatingCallToActionQuery } from '@/types/codegen-contentful'
import { useSafeAnalytics } from '@/utils/analytics'
import { FloatingCallToActionDisplay } from './FloatingCallToActionDisplay'

interface FloatingCallToActionProps {
  client: ApolloClient<object>
  locale: string
  preview: boolean
  region: string
  isGuildMember: boolean
  guildPermissions?: GuildPermissions
}

export const FloatingCallToAction: React.FC<FloatingCallToActionProps> = ({
  client,
  locale,
  preview,
  region,
  isGuildMember,
  guildPermissions,
}) => {
  const { track } = useSafeAnalytics()
  const [getFloatingCta, { data, loading }] = useLazyQuery<GetFloatingCallToActionQuery>(
    GET_FLOATING_CALL_TO_ACTION_QUERY,
    {
      client,
    },
  )

  const firstExperimentCTA = {
    backgroundImage: [
      {
        url: 'https://images.angelstudios.com/image/upload/v1739554074/angel-studios/landing/Corner%20CTA/KoK_CornerPin-Apr_11.png',
        version: '1739554074',
        public_id: 'angel-studios/landing/Corner%20CTA/KoK_CornerPin-Apr_11',
      },
    ],
    guildBackgroundImage: [
      {
        url: 'https://images.angelstudios.com/image/upload/v1739555133/angel-studios/landing/Corner%20CTA/KoK_CornerPin-Apr_11-v2.png',
        version: '1739555133',
        public_id: 'angel-studios/landing/Corner%20CTA/KoK_CornerPin-Apr_11-v2',
      },
    ],
    description: 'APR 11',
    buttonHref: '/tickets/king-of-kings?utm_source=web&utm_medium=cta&utm_campaign=home_banner',
    buttonIcon: 'play',
    buttonText: 'Get Showtimes',
    descriptionColor: '#BA966B',
    guildButtonText: 'Claim Free Tickets',
    logoAltText: 'King of Kings - Get Showtimes',
    logoHeight: 38,
    logoWidth: 59,
    logoUrl: '/v1737739474/studio-app/catalog/b3155c45-8dab-4bda-a317-48ba8f8c3154',
    title: 'In Theaters',
    trailerUrl: 'https://www.youtube.com/embed/wQmWmMR7710',
  }

  const handleClick = useCallback(() => {
    track('On Tap', {
      element_name: 'cta_tickets_floating',
      value: data?.callToActionCollection?.items?.[0],
    })
  }, [data, track])

  useEffect(() => {
    if (locale && region) {
      getFloatingCta({
        variables: {
          locale,
          preview,
          region,
        },
      })
    }
  }, [getFloatingCta, locale, preview, region])

  const firstCTA = data?.callToActionCollection?.items?.[0]
  if (loading || !firstCTA) return null

  return (
    <div>
      <LazyHydrate whenVisible>
        <GBFadeInExperiment experimentName="rule_breakers_king_of_kings_floating_cta" defaultValue={false}>
          <ExperimentVariation variation={true}>
            <div className="hidden sm:block">
              <FloatingCallToActionImage
                floatingCta={firstExperimentCTA}
                onClick={handleClick}
                isGuildMember={isGuildMember}
                guildPermissions={guildPermissions}
                kingOfKings
              />
            </div>
            <div className="block w-full sm:hidden">
              <FloatingCallToActionDisplay
                floatingCta={firstExperimentCTA}
                onClick={handleClick}
                isGuildMember={isGuildMember}
                guildPermissions={guildPermissions}
              />
            </div>
          </ExperimentVariation>
          <ExperimentVariation variation={false}>
            <div className="hidden sm:block">
              <FloatingCallToActionImage
                floatingCta={firstCTA}
                onClick={handleClick}
                isGuildMember={isGuildMember}
                guildPermissions={guildPermissions}
              />
            </div>
            <div className="block w-full sm:hidden">
              <FloatingCallToActionDisplay
                floatingCta={firstCTA}
                onClick={handleClick}
                isGuildMember={isGuildMember}
                guildPermissions={guildPermissions}
              />
            </div>
          </ExperimentVariation>
          <ExperimentVariation default>
            <div className="hidden sm:block">
              <FloatingCallToActionImage
                floatingCta={firstCTA}
                onClick={handleClick}
                isGuildMember={isGuildMember}
                guildPermissions={guildPermissions}
              />
            </div>
            <div className="block w-full sm:hidden">
              <FloatingCallToActionDisplay
                floatingCta={firstCTA}
                onClick={handleClick}
                isGuildMember={isGuildMember}
                guildPermissions={guildPermissions}
              />
            </div>
          </ExperimentVariation>
        </GBFadeInExperiment>
      </LazyHydrate>
    </div>
  )
}
